.heading {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 1rem;
  font-weight: bold;
}
.img-holder {
  margin: auto;
  width: 25%;
  height: 25%;
  border: 3px white solid;
  border-radius: 5px;
  margin-top: 1rem;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10%, 0%;
}
/* #input[type="file"] {
  display: none;
} */

.button {
  width: 300px;
  border: 15px solid green;
  padding: 50px;
  margin: 20px;
}

.container {
  display: flex; /* or inline-flex */
  flex-direction: row;
}
