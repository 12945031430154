.item {
  width: 33%;
  padding-bottom: 15%;

  @media (max-width: 500px) {
    background-color: dodgerblue;
    margin-bottom: 50%;
  }

  @media (max-width: 800px) {
    background-color: blue;
    margin-bottom: 50%;
  }
}
