.auth-form {
  width: 500px;
  padding: 25px;
  max-width: 100%;
  border-radius: 10px;
  background-color: #15202b;
  box-shadow: 0px 0px 10px black;
}
.auth-form label {
  display: block;
  /* margin-bottom: 25px; */
  color: white;
}
.auth-form input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: #d2d2d2;
}
.auth-form button {
  padding: 10px;
  border-radius: 3px;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  background-color: #030a24;
}
.auth-form button:hover {
  box-shadow: 0px 0px 3px black;
}
.auth-form button:disabled {
  color: darkgray;
  cursor: not-allowed;
  background-color: gray;
}
.auth-link-container {
  margin-top: 25px;
  margin-bottom: 25px;
}
.auth-link {
  color: gray;
  cursor: pointer;
  font-weight: bold;
}
.auth-link:hover {
  color: #5c5caa;
}
.error {
  color: #cd0000;
  font-weight: bold;
}
