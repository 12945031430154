html,
body {
  @media (min-width: 1024px) {
    .reg- {
      &hidden {
        visibility: hidden;
        display: none;
      }
      &shown {
        visibility: visible;
        display: inline;
      }
      &flex- {
        &container {
          display: flex;
          // background-color: DodgerBlue;
        }
        &auto {
          flex: 1 1 auto;
        }
        &center {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &space-between {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &start {
          display: flex;
          justify-content: flex-start;
        }
        &end {
          display: flex;
          justify-content: flex-end;
        }
        &full {
          flex: 1 1 100%;
        }
        &ninety {
          flex: 1 1 90%;
        }
        &eighty {
          flex: 1 1 80%;
        }
        &seventy {
          flex: 1 1 70%;
        }
        &three-quarters {
          flex: 1 1 75%;
        }
        &two-thirds {
          flex: 1 1 66.66%;
        }
        &sixty {
          flex: 1 1 60%;
        }
        &half {
          flex: 1 1 50%;
        }
        &forty {
          flex: 1 1 40%;
        }
        &third {
          flex: 1 1 33.33%;
        }
        &thirty {
          flex: 1 1 30%;
        }
        &quarter {
          flex: 1 1 25%;
        }
        &twenty {
          flex: 1 1 20%;
        }
        &ten {
          flex: 1 1 10%;
        }
        &five {
          flex: 1 1 5%;
        }
      }
      &row-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
      }
      &column-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
      }
      &column-container-nowrap {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
      }
      &display- {
        &block {
          display: block;
        }
      }
      &m- {
        &zero {
          margin: 0;
        }
      }
      &mt- {
        &5 {
          margin-top: 5px;
        }
        &10 {
          margin-top: 10px;
        }
        &20 {
          margin-top: 20px;
        }
        &30 {
          margin-top: 30px;
        }
        &40 {
          margin-top: 40px;
        }
        &50 {
          margin-top: 50px;
        }
      }
      &mb- {
        &5 {
          margin-bottom: 5px;
        }
        &10 {
          margin-bottom: 10px;
        }
        &20 {
          margin-bottom: 20px;
        }
        &30 {
          margin-bottom: 30px;
        }
      }
      &ml- {
        &5 {
          margin-left: 5px;
        }
        &10 {
          margin-left: 10px;
        }
        &15 {
          margin-left: 10px;
        }
        &20 {
          margin-left: 20px;
        }
        &25 {
          margin-left: 25px;
        }
        &30 {
          margin-left: 30px;
        }
      }
      &mr- {
        &0 {
          margin-right: 0px;
        }
        &5 {
          margin-right: 5px;
        }
        &10 {
          margin-right: 10px;
        }
        &20 {
          margin-right: 20px;
        }
        &30 {
          margin-right: 30px;
        }
      }
      &pt- {
        &5 {
          padding-top: 5px;
        }
        &10 {
          padding-top: 10px;
        }
        &20 {
          padding-top: 20px;
        }
        &30 {
          padding-top: 30px;
        }
      }
      &pb- {
        &5 {
          padding-bottom: 5px;
        }
        &10 {
          padding-bottom: 10px;
        }
        &20 {
          padding-bottom: 20px;
        }
        &30 {
          padding-bottom: 30px;
        }
      }
      &full- {
        &width {
          width: 100%;
        }
        &height {
          height: 100%;
        }
      }
    }
    .carousel-error {
      text-align: center !important;
      font-size: larger !important;
    }
    .error-text {
      color: red !important;
    }
  }
}
