.photo {
  margin: 0 auto;
}
.photo:hover {
  cursor: pointer;
}

.flex-1-0-0 {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  /* background: rgb(159, 159, 206); */
  width: 100%;
  height: 100%;
  min-height: 150px;
}

.x-right {
  justify-content: flex-end;
}

.y-bottom {
  align-self: flex-end;
}
.small-div {
  padding: 10px;
}

.flex-center,
.flex-center-bottom {
  align-items: center;
  /* background-color: #739fd0; */
  color: #000000;
  display: flex;
}

.flex-center {
  height: 400px;
  justify-content: center;
}

.flex-center-bottom {
  justify-content: flex-end;
}

.flex-item-center {
  /* border: solid 2px #4675aa; */
  font-family: Arial, sans-serif;
  font-size: 1.6em;
  line-height: 1px;
  padding: 0 3px;
}
