.title-text {
  color: rgb(28, 26, 189);
  font-size: larger;
}

.wrg-toggle--checked .wrg-toggle-check {
  opacity: 1;
}
.wrg-toggle--checked .wrg-toggle-uncheck {
  opacity: 0;
}
.wrg-toggle--checked .wrg-toggle-circle {
  left: 27px;
}
.carousel-error {
  text-align: center !important;
  font-size: larger !important;
}
.error-text {
  color: red !important;
}
