.test {
  @media (min-width: 0) {
    background-color: lightblue;
  }

  @media (min-width: 500px) {
    background-color: dodgerblue;
  }

  @media (min-width: 800px) {
    background-color: blue;
  }

  @media (min-width: 1280px) {
    background-color: darkblue;
  }
}
