.main {
  /* height: 100%; */
  /* border: 5px solid green; */
  /* background-color: hotpink; */
}
.flex-container {
  display: flex;

  /* border: 2px solid green; */
}

.flex-child {
  /* flex: 1; */
  flex: 1 0 0;
  /* border: 2px solid yellow; */
  height: 80vh;
}

.flex-child:first-child {
  margin-right: 0px;
}

.tool-bar {
  /* 
  width: 100%;

  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(204, 204, 204);
  min-height: 55px;
  padding: 0px 12px;
  */
  height: 10vh;
  width: 100%;
  background-color: red;
  /* padding-top: 5px;
  padding-left: 5px; */
}
.search-page-container {
  /* display: flex; or inline-flex */
  flex-direction: row-reverse;
}

.search-page-list-container {
  /* background-color: yellow; */
  /* width: 50%; */
}

.search-page-map-container {
  /* background-color: blueviolet;

  width: 100%; */
}

.map-container {
  height: 100%;
}

.outter {
  flex: 1; /* 1 and it will fill whole space left if no flex value are set to other children*/
  flex-direction: column;
  /* background: gold; */
  overflow: auto;
}

.search-page--hidden {
  display: none;
}

.plane-card {
  height: 100%;
}

@media (min-width: 800px) {
  .search-page--hidden {
    display: block;
  }

  .map-view-button {
    display: none;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

.filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
}
