// .App {
//   display: flex;
//   height: 100vh;
//   align-items: flex-start;
//   justify-content: center;
//   background: linear-gradient(#fdfcfb, #e2d1c3);
//   padding-top: 120px;
//   box-sizing: border-box;
// }

.search-bar {
  display: flex;
}

.width-26 {
  width: 26%;
}
.width-100 {
  width: 100% !important;
}

.width-80 {
  width: 80% !important;
}

.height {
  height: 100%;
}

/* Dropdown Button */
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

/* The search field */
#myInput {
  box-sizing: border-box;
  // background-image: url("searchicon.png");
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {
  outline: 3px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: show;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  border: 1px solid #ddd;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: right;
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {
  // background-color: #f1f1f1;
  background-color: #ddd;
}

.hidden {
  //display: none;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
